import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { TableHeaderBtn } from '@/App/Components/TableHeader/TableHeader'
import { BaseUser } from '@/App/Services/interfaces/user.interface'
import i18n from '@/plugins/i18n'

const locale = localStorage.getItem('localization') || 'en'

export const TABLE_HEADERS: VuetifyTableHeader[] = [
  { text: i18n.t('usersPage.constants.statusText', locale).toString(), align: 'left', sortable: true, value: 'status' },
  { text: i18n.t('usersPage.constants.loginText', locale).toString(), align: 'left', sortable: true, value: 'login' },
  { text: i18n.t('usersPage.constants.emailText', locale).toString(), align: 'left', sortable: true, value: 'email' },
  { text: i18n.t('usersPage.constants.roleText', locale).toString(), align: 'left', sortable: true, value: 'role' },
  { text: i18n.t('usersPage.constants.organizationText', locale).toString(), align: 'left', sortable: true, value: 'organizationName' },
  { text: i18n.t('usersPage.constants.actionText', locale).toString(), value: 'left', align: 'center', sortable: false }
]

export const TABLE_HEADER_BTN: TableHeaderBtn[] = [
  { icon: 'fa fa-user-plus', tooltip: i18n.t('usersPage.constants.addNewUserTooltipText', locale).toString(), action: 'onUserCreate' }
]

export const USER_FORM: User = {
  organizationId: '',
  login: '',
  password: '',
  role: 'operator',
  email: '',
  timezone: '+0',
  flespiToken: '',
  maxMonitoringDashboards: 0,
  maxInsightDashboards: 0,
  maxWidgets: 0,
  maxNotification: 0,
  maxScheduleReports: 0,
  maxManagers: 0,
  maxOperators: 0,
  maxDevices: 0
}

export interface User extends BaseUser {
  password ?: string
}
