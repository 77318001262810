import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { UserService } from '@/App/Services/User.service'
import { User, USER_FORM } from '@/App/Pages/Users/constants'
import TimezoneSelect from './TimezoneSelect/Timezone-Select.vue'
import OrganizationSelect from '@/App/Components/OrganizationSelect/Organization-Select.vue'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { BaseUser, IUser, UserInfo } from '@/App/Services/interfaces/user.interface'
import { UserRole, ValidationResult } from '@/types'

const userStore = namespace('User')
const sharedStore = namespace('Shared')

@Component({
  components: { TimezoneSelect, OrganizationSelect }
})
export default class UsersDialog extends Mixins(Validator) {
  $refs: {
    form: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.State
  public readonly userInfo: UserInfo

  @userStore.Mutation
  private readonly addUser: (user: IUser) => void

  @userStore.Mutation
  private readonly updateUser: (user: IUser) => void

  public isOpen = false
  public isCoreTokenShow = false;
  public isLoading = false
  public form = false
  public roleItems: string[] = []
  public user: User = { ...USER_FORM }
  public id: string | null = null

  public open(): void {
    this.isOpen = true
    this.fillUserRolesSelect()
  }

  public close(): void {
    this.isOpen = false

    this.user = { ...USER_FORM }
    this.id = null

    this.$refs.form.resetValidation()
  }

  public async submit(): Promise<void> {
    if (this.id) {
      await this.update()
    } else {
      await this.store()
    }
  }

  public async store(): Promise<void> {
    try {
      this.isLoading = true

      this.addUser( await UserService.store({ ...this.user, password: this.user.password! }))

      this.setNotification({ text: this.$t('usersPage.usersDialog.userCreatedNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }

  edit(user: IUser) {
    this.id = user._id

    delete user.status
    delete user.organizationName
    delete user._id

    this.user = { ...USER_FORM, ...user }

    this.fillUserRolesSelect(user.role)
    this.isOpen = true
  }

  public async update(): Promise<void> {
    try {
      this.isLoading = true

      let candidate = { ...this.user }
      if (this.userInfo.role === 'manager') {
        candidate = this.clearUserObjFromLimits(candidate)
      }

      const user = await UserService.update(this.id!, candidate)

      this.updateUser(user)

      this.setNotification({ text: this.$t('usersPage.usersDialog.userUpdatedNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }

  private clearUserObjFromLimits(user: BaseUser): BaseUser {
    delete user.maxMonitoringDashboards
    delete user.maxInsightDashboards
    delete user.maxWidgets
    delete user.maxNotification
    delete user.maxScheduleReports
    delete user.maxManagers
    delete user.maxOperators
    delete user.maxDevices
    delete user.maxGeofences

    return user
  }

  public passwordValidator(val: string): ValidationResult {
    if (!this.id) {
      return this.validator.isEmpty(val)
    }

    return true
  }

  public flespiTokenValidator(val: string): ValidationResult {
    if (val && val && val.length < 64 || val.length > 64) {
      return this.$t('usersPage.usersDialog.validatorText').toString()
    }

    return true
  }

  public maxItemsValidator(val: number, type: ItemsValidatorType): ValidationResult {
    if (!this.isUserRoot) {
      return this.validator.lessThan({
        val,
        val2: this.userInfo[type]!,
        text: `${this.$t( 'usersPage.usersDialog.accountLimitIs')} ${this.userInfo[type]}`
      })
    }

    return true
  }

  private fillUserRolesSelect(role?: UserRole): void {
    switch (this.userInfo.role) {
      case 'root':
        this.roleItems = [ 'admin' ]
        this.user.role = 'admin'
        break
      case 'admin':
        this.roleItems = [ 'manager', 'operator' ]
        this.user.role = 'manager'
        break
      case 'manager':
        this.roleItems = [ 'operator' ]
        this.user.role = 'operator'
        break

    }

    if (role) {
      if (!this.roleItems.includes(role)) {
        this.roleItems.push(role)
      }
      this.user.role = role
    }
  }

  get title(): string {
    return this.id
      ? this.$t('usersPage.usersDialog.editText').toString()
      : this.$t('usersPage.usersDialog.creatingNewText').toString()
      + this.$t('usersPage.usersDialog.userText').toString()
  }

  get isUserRoot(): boolean {
    return this.userInfo.role === 'root'
  }

  get isUserManager(): boolean {
    return this.userInfo.role === 'manager'
  }

  get isAccountLimitsBlockShow(): boolean {
    return this.user.role !== 'operator'
  }

  get buttonText(): string {
    return this.id
        ? this.$t('usersPage.usersDialog.updateText').toString()
        : this.$t('usersPage.usersDialog.saveText').toString()
  }
}

type ItemsValidatorType =
  'maxMonitoringDashboards' |
  'maxInsightDashboards' |
  'maxWidgets' |
  'maxNotification' |
  'maxScheduleReports'|
  'maxManagers' |
  'maxOperators' |
  'maxDevices'
