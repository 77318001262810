import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'

@Component
export default class TimezoneSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: string

  public timezone = ''
  public timezonesItems: string[] = [
    '-11:00',
    '-10:00',
    '-9:30',
    '-9:00',
    '-8:00',
    '-7:00',
    '-6:00',
    '-5:00',
    '-4:00',
    '-3:00',
    '-2:00',
    '-1:00',
    '+0:00',
    '+1:00',
    '+2:00',
    '+3:00',
    '+3:30',
    '+4:00',
    '+4:30',
    '+5:00',
    '+5:30',
    '+5:45',
    '+6:00',
    '+6:30',
    '+7:00',
    '+8:00',
    '+8:45',
    '+9:00',
    '+9:30',
    '+10:00',
    '+10:30',
    '+11:00',
    '+12:00',
    '+12:45',
    '+13:00',
    '+14:00'
  ]

  @Watch('value')
  private valueChanged(val: string): void {
    this.timezone = val
  }
}
