import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { UserService } from '@/App/Services/User.service'
import { TABLE_HEADERS, TABLE_HEADER_BTN } from '@/App/Pages/Users/constants'
import UsersDialog from '../UserDialog/Users-Dialog.vue'
import TableHeader from '@/App/Components/TableHeader/Table-Header.vue'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { IUser } from '@/App/Services/interfaces/user.interface'
import { UserStatus } from '@/types'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { TableHeaderBtn } from '@/App/Components/TableHeader/TableHeader'

const userStore = namespace('User')
const sharedStore = namespace('Shared')

@Component({
  components: { UsersDialog, TableHeader }
})
export default class UsersTable extends Vue {
  $refs: {
    userDialog: HTMLFormElement
  }

  @userStore.State
  public readonly users: IUser[]

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @userStore.Mutation
  private readonly setUsers: (users: IUser[]) => void

  @userStore.Mutation
  private readonly destroyUser: (id: string) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  public search = ''
  public isLoading = false
  public headers: VuetifyTableHeader[] = []
  public headerActionsBtn: TableHeaderBtn[] = []

  public onSearch(text: string): void {
    this.search = text
  }

  public openUserDialog(): void {
    this.$refs.userDialog.open()
  }

  public editUser(user: IUser): void {
    this.$refs.userDialog.edit(user)
  }

  public async deleteUser(id: string) {
    const title = this.$t('usersPage.usersTable.confirmTitleText').toString()
    const text = `
        ${this.$t('usersPage.usersTable.deleteUserConfirmText1')}<br/>
        ${this.$t('usersPage.usersTable.deleteUserConfirmText2')}<br/>
        ${this.$t('usersPage.usersTable.deleteUserConfirmText3')}
    `

    if (await this.confirm({ title, text })) {
      try {
        const deletedUsersIds = await UserService.destroy(id)

        deletedUsersIds.forEach(id => this.destroyUser(id))

        this.setNotification({ text: this.$t('usersPage.usersTable.userDeletedNotificationText').toString() })
      } catch {}
    }
  }

  public async onChangeStatus(_id: string, status: UserStatus): Promise<void> {
    try {
      this.isLoading = true

      await UserService.update(_id, { status })

      this.setUsers(await UserService.fetch())

      this.setNotification({ text: this.$t('usersPage.usersTable.statusChangedNotificationText').toString() })
    } catch (e) {
      // TODO add here return old user status if throw error on update
    } finally {
      this.isLoading = false
    }
  }

  public async resendActivationLink(_id: string): Promise<void> {
    try {
      this.isLoading = true

      await UserService.resendActivationLink(_id)

      this.setNotification({ text: this.$t('usersPage.usersTable.activationLinkSentNotificationText').toString() })
    } catch {} finally {
      this.isLoading = false
    }
  }

  private async mounted(): Promise<void> {
    this.headers = TABLE_HEADERS
    this.headerActionsBtn = TABLE_HEADER_BTN
    try {
      this.isLoading = true

      this.setUsers(await UserService.fetch())
    } catch {} finally {
      this.isLoading = false
    }
  }
}
